import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box, Button, Tag } from '@hero-design/react';

import { TSkill } from 'src/modules/User/types';

import { DetailContainer, DetailHeader } from './SharedComponents';

type SkillsProps = {
  skills: TSkill[];
};

const MINIMUM_SKILLS_DISPLAYED = 6;

const Skills = ({ skills }: SkillsProps) => {
  const theme = useTheme();
  const [showFull, setShowFull] = useState(false);

  const displayedSkills = useMemo(
    () => (showFull ? skills : skills.slice(0, MINIMUM_SKILLS_DISPLAYED)),
    [skills, showFull]
  );

  return (
    <DetailContainer>
      <DetailHeader text="Skills" />
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.space.small,
          }}
        >
          {displayedSkills.map(({ id, title }) => (
            <Tag key={id} text={title} />
          ))}
        </Box>

        {skills.length > MINIMUM_SKILLS_DISPLAYED && (
          <Button
            variant="text"
            text={showFull ? 'See less' : 'See more'}
            onClick={() => setShowFull(!showFull)}
            sx={{ p: 0 }}
          />
        )}
      </Box>
    </DetailContainer>
  );
};

export default Skills;
