import { useEffect } from 'react';

import {
  Box,
  Icon,
  Modal,
  Spinner,
  Tooltip,
  Typography,
} from '@hero-design/react';

import useFetchHighlightQuestions from 'src/modules/User/components/SetupJobPreferences/SetupJobPreferencesV2/hooks/useFetchHighlightQuestions';
import useFetchJobPreferences from 'src/modules/User/hooks/useFetchJobPreferences';
import useFetchUserProfile from 'src/modules/User/hooks/useFetchUserProfile';

import Certifications from './Certifications';
import EducationHistories from './EducationHistory';
import EmploymentHistories from './EmploymentHistory';
import CandidateHighlightSection from './Highlights';
import Skills from './Skills';
import Summary from './Summary';

const PreviewSmartMatchProfile = ({
  previewProfile,
  setPreviewProfile,
}: {
  previewProfile: boolean;
  setPreviewProfile: (change: boolean) => void;
}) => {
  const { userProfileData, isFetchingUserProfile } = useFetchUserProfile();
  const { groupedJobPreferencesData, isFetchingJobPreferences } =
    useFetchJobPreferences();
  const {
    fetcher: fetchHighlightQuestions,
    data: highlightQuestionsData,
    loading: isFetchingHighLight,
  } = useFetchHighlightQuestions({});

  useEffect(() => {
    fetchHighlightQuestions();
  }, []);

  const userProfile = userProfileData?.data;
  const jobsByCompany = userProfile?.employment_histories || [];
  const highlightQuestions = highlightQuestionsData?.data?.items || [];

  return (
    <Modal
      open={previewProfile}
      variant="basic"
      onClose={() => setPreviewProfile(false)}
      title="Preview SmartMatch profile"
      body={
        isFetchingUserProfile ||
        isFetchingJobPreferences ||
        isFetchingHighLight ? (
          <Spinner />
        ) : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography.Text fontWeight="semi-bold" fontSize={18}>
                {`${userProfile?.first_name} ${userProfile?.last_name}`}
              </Typography.Text>
              {userProfile?.profile_strength_score === 100 && (
                <Tooltip
                  content="Your profile is complete"
                  placement="bottom"
                  target={
                    <Icon
                      data-test-id="profile-completion-badge"
                      icon="circle-ok-outlined"
                      size="xlarge"
                    />
                  }
                  sx={{ ml: 'small' }}
                />
              )}
            </Box>

            <Typography.Text intent="subdued">
              {userProfile?.headline}
            </Typography.Text>

            {!!userProfile && (
              <CandidateHighlightSection
                groupedJobPreferencesData={groupedJobPreferencesData}
                userProfile={userProfile}
                highlightQuestions={highlightQuestions}
              />
            )}

            {!!userProfile?.summary && (
              <Summary summary={userProfile?.summary} />
            )}

            {jobsByCompany.length > 0 && (
              <EmploymentHistories employmentHistories={jobsByCompany} />
            )}

            {!!userProfile?.education_histories &&
              userProfile.education_histories.length > 0 && (
                <EducationHistories
                  educationHistories={userProfile?.education_histories}
                />
              )}

            {!!userProfile?.skills && userProfile.skills.length > 0 && (
              <Skills skills={userProfile?.skills} />
            )}

            {!!userProfile?.certifications &&
              userProfile.certifications.length > 0 && (
                <Certifications
                  certificationDetails={userProfile?.certifications}
                />
              )}
          </>
        )
      }
    />
  );
};

export default PreviewSmartMatchProfile;
