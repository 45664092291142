import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { UnpaginatedResponse } from 'src/modules/CareersPage/types';

import { THighlightQuestion } from '../../types';

export type TFetchHighlightQuestionsParams = {
  standard_job_info_ids: Array<string>;
};

export type TFetchHighlightQuestionsResponse = {
  data: UnpaginatedResponse<THighlightQuestion>;
};

type TFetchHighlightQuestionsProps = Pick<
  ApiConfigs<TFetchHighlightQuestionsResponse, TFetchHighlightQuestionsParams>,
  'onCompleted' | 'onFailed'
>;

const useFetchHighlightQuestions = ({
  onCompleted,
  onFailed,
}: TFetchHighlightQuestionsProps) => {
  const { fetcher, loading, data } = useFetch<
    TFetchHighlightQuestionsResponse,
    TFetchHighlightQuestionsParams
  >({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/highlights`,
    onCompleted,
    onFailed,
  });

  return {
    fetcher,
    data,
    loading,
  };
};

export default useFetchHighlightQuestions;
