import { IconProps, TagProps } from '@hero-design/react';

export const genProfileHealthContent = (
  profileStrengthScore: number
): {
  text: string;
  icon?: IconProps['icon'];
  tagIntent: TagProps['intent'];
} => {
  switch (Math.floor(profileStrengthScore / 25)) {
    case 0:
    case 1:
      return { text: 'low', icon: 'arrow-down', tagIntent: 'primary' };
    case 2:
      return { text: 'medium', tagIntent: 'warning' };
    case 3:
      return { text: 'high', icon: 'arrow-up', tagIntent: 'success' };
    default:
      return { text: 'high', icon: 'arrow-up', tagIntent: 'success' };
  }
};
