import React from 'react';

import { Box, Typography } from '@hero-design/react';

import { getPeriod } from '../../../utils/helpers';
import { TEducationHistory } from '../../../types';

import {
  DetailContainer,
  DetailDuration,
  DetailHeader,
} from './SharedComponents';

type EducationHistoriesProps = {
  educationHistories: TEducationHistory[];
};

type EducationDetailProps = {
  educationDetail: TEducationHistory;
  isLastHistory: boolean;
};

const EducationDetail = ({
  educationDetail,
  isLastHistory,
}: EducationDetailProps) => {
  const { school, degree, field_of_study } = educationDetail;

  return (
    <Box mb={isLastHistory ? undefined : 'medium'}>
      <Typography.Text fontWeight="semi-bold">
        {degree || field_of_study}
      </Typography.Text>
      <Typography.Text intent="subdued">
        <DetailDuration
          period={{
            periodLength: getPeriod(educationDetail),
            duration: undefined,
          }}
        />
      </Typography.Text>
      <Typography.Text intent="subdued">{school}</Typography.Text>
    </Box>
  );
};

const EducationHistories = ({
  educationHistories,
}: EducationHistoriesProps) => (
  <DetailContainer>
    <DetailHeader text="Education" />
    {educationHistories.map((education, index) => (
      <EducationDetail
        key={education.id}
        educationDetail={education}
        isLastHistory={index === educationHistories.length - 1}
      />
    ))}
  </DetailContainer>
);

export default EducationHistories;
