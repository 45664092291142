import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Box, Button, Typography } from '@hero-design/react';

type TPeriod = {
  periodLength: string;
  duration: string | undefined;
};

interface DetailHeaderProps {
  text: string;
}

interface DetailContainerProps {
  children: ReactNode;
}

interface DetailDurationProps {
  period: TPeriod;
  isVerified?: boolean;
}

const DetailHeader = ({ text }: DetailHeaderProps) => (
  <Typography.Text fontSize={18} fontWeight="semi-bold" sx={{ pb: 'medium' }}>
    {text}
  </Typography.Text>
);

const DetailContainer = ({ children }: DetailContainerProps) => (
  <Box pb="xlarge">{children}</Box>
);

const DetailDuration = ({ period, isVerified }: DetailDurationProps) => {
  const theme = useTheme();

  const { periodLength, duration } = period;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography.Text
        tagName="p"
        intent="subdued"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <span>{periodLength}</span>
        {duration && (
          <>
            <span
              style={{
                fontSize: theme.fontSizes.xsmall,
                paddingRight: theme.space.small,
                paddingLeft: theme.space.small,
              }}
            >
              {'\u2B24'}
            </span>
            <span> {duration}</span>
          </>
        )}
      </Typography.Text>
      {isVerified && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Button.Icon
            intent="primary"
            icon="circle-check"
            size="large"
            sx={{ mr: 'small' }}
          />
          <Typography.Text
            intent="primary"
            fontSize={12}
            fontWeight="semi-bold"
          >
            Verified
          </Typography.Text>
        </Box>
      )}
    </Box>
  );
};

export { DetailContainer, DetailDuration, DetailHeader };
