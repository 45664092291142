import capitalize from 'lodash/fp/capitalize';
import isEmpty from 'lodash/fp/isEmpty';

import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Box, Icon, Tooltip, Typography } from '@hero-design/react';

import {
  GroupedJobPreferencesData,
  TUserProfile,
} from 'src/modules/User/types';

import { THighlightQuestion } from '../../SetupJobPreferences/types';

import {
  formatHighlightQuestions,
  parseEducationLevel,
  parseNoticePeriod,
  parseSalaryInfo,
} from './helpers';

const CandidateHighlightSectionItem = ({
  info,
  emptyMessage,
  shouldGreyOut,
  tooltipPlacement,
}: {
  info?: boolean | string;
  emptyMessage: string;
  shouldGreyOut: boolean;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
}) => (
  <Tooltip
    visible={shouldGreyOut}
    placement={tooltipPlacement}
    content="Candidate has not provided this information"
    target={
      <Typography.Text intent="subdued" fontSize={12}>
        <Icon
          icon={shouldGreyOut ? 'circle-question-outlined' : 'checkmark'}
          sx={{ mr: 'small' }}
        />
        {shouldGreyOut ? emptyMessage : info}
      </Typography.Text>
    }
  />
);

const CandidateHighlightSection = ({
  userProfile,
  groupedJobPreferencesData,
  highlightQuestions,
}: {
  userProfile: TUserProfile;
  groupedJobPreferencesData: GroupedJobPreferencesData;
  highlightQuestions: THighlightQuestion[];
}) => {
  const theme = useTheme();

  const {
    work_eligibility_countries = [],
    country_code,
    education_histories = [],
  } = userProfile;

  const eligibleForWork = work_eligibility_countries?.includes(
    country_code || ''
  );

  const { WorkTypePreference, SalaryInfo, AvailabilityToStart } =
    groupedJobPreferencesData;

  const salary = !isEmpty(SalaryInfo) ? parseSalaryInfo(SalaryInfo) : '';
  const noticePeriod = !isEmpty(AvailabilityToStart)
    ? parseNoticePeriod(AvailabilityToStart[0].value)
    : '';

  const workTypeString = !isEmpty(WorkTypePreference)
    ? WorkTypePreference.map(({ value }) => capitalize(value)).join(', ')
    : '';
  const highestEducationLevel = parseEducationLevel(
    education_histories.map(({ education_level }) => education_level)
  );

  const formattedHighlightQuestions =
    formatHighlightQuestions(highlightQuestions);

  const hasNoHighlightData = useMemo(
    () =>
      !eligibleForWork &&
      !salary &&
      !workTypeString &&
      !noticePeriod &&
      !isEmpty(formattedHighlightQuestions),
    [
      eligibleForWork,
      formattedHighlightQuestions,
      noticePeriod,
      salary,
      workTypeString,
    ]
  );

  if (hasNoHighlightData) {
    return null;
  }

  const candidateHighlightSectionList = [
    {
      id: 'eligibleForWork',
      info: eligibleForWork ? 'Work eligibility verified' : false,
      emptyMessage: 'Work eligibility unknown',
    },
    {
      id: 'salary',
      info: salary,
      emptyMessage: 'Salary unknown',
    },
    {
      id: 'workType',
      info: workTypeString,
      emptyMessage: 'Work type unknown',
    },
    {
      id: 'noticePeriod',
      info: noticePeriod,
      emptyMessage: 'Notice period unknown',
    },
  ];

  return (
    <Box
      data-test-id="candidate-highlight-section"
      pt="medium"
      pb="medium"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.space.medium,
      }}
    >
      <Box sx={{ flex: 1 }}>
        {candidateHighlightSectionList.map(({ id, info, emptyMessage }) => (
          <Box key={id}>
            <CandidateHighlightSectionItem
              info={info}
              emptyMessage={emptyMessage}
              shouldGreyOut={!info}
              tooltipPlacement="right"
            />
          </Box>
        ))}
      </Box>

      <Box>
        {highestEducationLevel && (
          <CandidateHighlightSectionItem
            info={highestEducationLevel}
            emptyMessage=""
            shouldGreyOut={false}
          />
        )}
        {!isEmpty(formattedHighlightQuestions) && (
          <Box sx={{ flex: 1 }}>
            {formattedHighlightQuestions.map(({ id, name, answer }) => (
              <Box key={id}>
                <CandidateHighlightSectionItem
                  info={name}
                  emptyMessage={name}
                  shouldGreyOut={answer === 'no'}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CandidateHighlightSection;
