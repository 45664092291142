import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box, Button, ButtonProps, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import useWindowSize from '@shared/hooks/useWindowSize';

type StatusComponentProps = {
  imageSrc: string;
  imageAltText?: string;
  titleText: string | ReactElement;
  subtitleText: string | ReactElement;
  buttonProps: {
    text: string;
    url?: string;
    variant: ButtonProps['variant'];
    onClick?: () => void;
  };
};

const StatusComponent = ({
  imageSrc,
  imageAltText,
  titleText,
  subtitleText,
  buttonProps,
}: StatusComponentProps) => {
  const { isSmallScreen } = useWindowSize();
  const { push } = useRouter();
  const theme = useTheme();
  const { onClick, text, variant, url = '' } = buttonProps;

  return (
    <Box
      borderRadius="medium"
      boxShadow="default"
      pt="large"
      pb="large"
      pl="medium"
      pr="medium"
      sx={{ display: 'flex', flex: 1 }}
    >
      {!isSmallScreen && (
        <Image
          src={imageSrc}
          alt={imageAltText}
          width={100}
          height={100}
          layout="fixed"
          style={{
            borderRadius: theme.radii.medium,
            paddingRight: theme.space.medium,
          }}
        />
      )}
      <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography.Title level={5} sx={{ mb: 'xsmall' }}>
          {titleText}
        </Typography.Title>
        <Typography.Text
          intent="subdued"
          sx={{ mb: isSmallScreen ? 'small' : 'medium', flex: 1 }}
        >
          {subtitleText}
        </Typography.Text>
        <Button
          onClick={() => {
            if (url) {
              push(url);
            } else {
              onClick?.();
            }
          }}
          variant={variant}
          text={text}
          sx={{ width: 'min-content' }}
        />
      </Box>
    </Box>
  );
};

export default StatusComponent;
