import { Typography } from '@hero-design/react';
import { TCertification } from '@shared/certifications/types';

import { DetailContainer, DetailHeader } from './SharedComponents';

type CertificationsProps = {
  certificationDetails: TCertification[];
};

const Certifications = ({ certificationDetails }: CertificationsProps) => (
  <DetailContainer>
    <DetailHeader text="Certifications" />
    {certificationDetails.map(certification => (
      <Typography.Text key={certification.id} sx={{ mb: 'medium' }}>
        {certification.name}
      </Typography.Text>
    ))}
  </DetailContainer>
);

export default Certifications;
