import React from 'react';
import styled from 'styled-components';

import { Box, Grid, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import { useOnboardingTrack } from '@shared/hooks/useOnboardingTrack';
import useWindowSize from '@shared/hooks/useWindowSize';
import { TRACK_ONBOARDING_FLOW_VIEW_SETUP_SUCCESS } from '@shared/trackTools/mixpanel';

import JobSearchStatus from './Statuses/JobSearch';
import ProfileProgressStatus from './Statuses/ProfileProgress';
import SmartMatchesStatus from './Statuses/SmartMatches';

const ComponentWrapper = styled(Box)`
  width: 70%;

  ${mediaMaxQueries.xl} {
    width: 80%;
  }

  ${mediaMaxQueries.md} {
    width: auto;
  }
  && {
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledGridCol = styled(Grid.Col)`
  &&& {
    display: flex;
  }
`;

type ProfileHomeProps = {
  firstName: string;
  smartMatchCount?: number;
  profileStrengthScore?: number;
  isLandingPage?: boolean;
};

export const renderGreeting = (firstName: string, isLandingPage: boolean) => {
  if (!firstName) {
    return isLandingPage
      ? `Let's find your next job.`
      : `Let's start finding you work!`;
  }

  return isLandingPage
    ? `${firstName}, let's find your next job.`
    : `Let's start finding you work, ${firstName}!`;
};

const ProfileHome = ({
  firstName,
  smartMatchCount = 0,
  profileStrengthScore = 0,
  isLandingPage = false,
}: ProfileHomeProps) => {
  useOnboardingTrack({
    eventName: TRACK_ONBOARDING_FLOW_VIEW_SETUP_SUCCESS,
  });

  const { isSmallScreen } = useWindowSize();

  return (
    <>
      <Box
        bgColor={
          isSmallScreen ? 'default-light-background' : 'default-background'
        }
        style={{ width: '100%' }}
      >
        <ComponentWrapper
          pt={isSmallScreen ? 'medium' : 'xxxxlarge'}
          pb={isSmallScreen ? 'medium' : 'xxxxlarge'}
          pl="medium"
          pr="medium"
          style={{
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: isSmallScreen ? 'column-reverse' : 'row',
          }}
        >
          <Box
            pt={isSmallScreen ? 'medium' : undefined}
            pr={isSmallScreen ? undefined : 'large'}
            sx={{ alignSelf: 'center' }}
          >
            <Typography.Title
              level={1}
              sx={{ mb: isSmallScreen ? 'small' : 'medium' }}
            >
              {renderGreeting(firstName, isLandingPage)}
            </Typography.Title>
            <Typography.Text>
              {isLandingPage ? (
                <>
                  <p>
                    We&apos;re showing your profile to employers looking to hire
                    people like you. If they&apos;re interested, they&apos;ll
                    reach out and invite you to apply.
                  </p>
                  <p>
                    With over 300,000+ employers on our platform, there&apos;ll
                    be plenty to choose from. Just keep your profile up-to-date
                    as the opportunities roll in.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Nice one! We&apos;re showing your profile to employers
                    looking to hire people like you. If they&apos;re interested,
                    they&apos;ll reach out.
                  </p>
                  <p>
                    With over 300,000+ employers on our platform, there&apos;ll
                    be plenty to choose from. Just sit back as the opportunities
                    roll in.
                  </p>
                </>
              )}
            </Typography.Text>
          </Box>
          <Image
            src="/assets/images/setup-profile-complete.png"
            alt="profile setup complete ready for matches"
            layout="responsive"
            style={{
              width: isSmallScreen ? '100%' : '50%',
              maxWidth: 700,
            }}
          />
        </ComponentWrapper>
      </Box>
      <ComponentWrapper p={'medium'}>
        <Grid
          sx={{
            pt: isSmallScreen ? 'medium' : 'xxxlarge',
            pb: isSmallScreen ? 'medium' : 'xxxlarge',
          }}
        >
          <Grid.Row
            gutter={isSmallScreen ? ['none', 'large'] : ['large', 'large']}
            sx={{ display: 'flex' }}
          >
            {isSmallScreen ? (
              <>
                <StyledGridCol span={[24, 24, 24, 12, 8]}>
                  <ProfileProgressStatus
                    profileStrengthScore={profileStrengthScore}
                  />
                </StyledGridCol>
                <StyledGridCol span={[24, 24, 24, 12, 8]}>
                  <SmartMatchesStatus smartMatchCount={smartMatchCount} />
                </StyledGridCol>
              </>
            ) : (
              <>
                <StyledGridCol span={[24, 24, 24, 12, 8]}>
                  <SmartMatchesStatus smartMatchCount={smartMatchCount} />
                </StyledGridCol>
                <StyledGridCol span={[24, 24, 24, 12, 8]}>
                  <ProfileProgressStatus
                    profileStrengthScore={profileStrengthScore}
                  />
                </StyledGridCol>
              </>
            )}
            <StyledGridCol span={[24, 24, 24, 12, 8]}>
              <JobSearchStatus />
            </StyledGridCol>
          </Grid.Row>
        </Grid>
      </ComponentWrapper>
    </>
  );
};

export default ProfileHome;
