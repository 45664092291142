import React from 'react';

import StatusComponent from '../StatusComponent';

const JobSearchStatus = () => (
  <StatusComponent
    imageSrc="/assets/images/jumping-over-search.svg"
    imageAltText="job search icon"
    titleText="Search for jobs"
    subtitleText="Got a specific job in mind? Search for roles and filter by industry, work type, work-from-home policies and more."
    buttonProps={{
      variant: 'outlined',
      text: 'Search for roles',
      url: '/search',
    }}
  />
);

export default JobSearchStatus;
