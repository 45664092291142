import { useState } from 'react';
import styled from 'styled-components';

import { Button, Typography } from '@hero-design/react';

import { DetailContainer, DetailHeader } from './SharedComponents';

const SeeMoreWrapper = styled.span<{ showFull: boolean }>`
  ${({ showFull }) => {
    if (showFull) {
      return `
        display: inline;
      `;
    }

    return `
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      hyphens: manual;
    `;
  }}
`;

type SummaryProps = {
  summary: string;
};

const Summary = ({ summary }: SummaryProps) => {
  const [showFullSummary, setShowFullSummary] = useState(false);

  return (
    <DetailContainer>
      <DetailHeader text="Summary" />
      <Typography.Text tagName="div">
        {summary.length > 150 ? (
          <>
            <SeeMoreWrapper showFull={showFullSummary}>
              {summary}
            </SeeMoreWrapper>
            <Button
              variant="text"
              intent="primary"
              sx={{ p: showFullSummary ? 'small' : 0 }}
              data-test-id="summary-see-more--and-less-button"
              text={showFullSummary ? 'See less' : 'See more'}
              onClick={() => setShowFullSummary(!showFullSummary)}
            />
          </>
        ) : (
          summary
        )}
      </Typography.Text>
    </DetailContainer>
  );
};

export default Summary;
