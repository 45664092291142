import React from 'react';

import {
  Icon,
  IconProps,
  Tag,
  TagProps,
  Timeline,
  Typography,
} from '@hero-design/react';

type ProfileHealthTimelineProps = {
  profileStrengthScore: number;
};

type TimelineTitleTagProps = {
  text: string;
  icon: IconProps['icon'];
  tagIntent: TagProps['intent'];
  variant: TagProps['variant'];
};

const TimelineTitleTag = ({
  tagIntent,
  icon,
  text,
  variant,
}: TimelineTitleTagProps) => (
  <Tag
    intent={tagIntent}
    text={
      <>
        <Icon icon={icon} sx={{ mr: 'xsmall' }} />
        {text}
      </>
    }
    variant={variant}
    sx={{ height: 'fit-content', mb: 'small' }}
  />
);

const ProfileHealthTimeline = ({
  profileStrengthScore,
}: ProfileHealthTimelineProps) => {
  const isLowStrength = profileStrengthScore < 50;
  const isMediumStrength =
    profileStrengthScore >= 50 && profileStrengthScore < 75;
  const isHighStrength = profileStrengthScore >= 75;

  return (
    <Timeline
      items={[
        {
          id: 'low_strength',
          text: (
            <TimelineTitleTag
              tagIntent="primary"
              icon="arrow-down"
              text="LOW"
              variant={isLowStrength ? 'outlined' : 'basic'}
            />
          ),
          intent: isLowStrength ? 'info' : undefined,
          helpText: (
            <Typography.Text intent="subdued">
              Your profile will not be shown to employers and you will have
              limited matches.
            </Typography.Text>
          ),
        },
        {
          id: 'medium_strength',
          text: (
            <TimelineTitleTag
              tagIntent="warning"
              icon="arrow-up"
              text="MEDIUM"
              variant={isMediumStrength ? 'outlined' : 'basic'}
            />
          ),
          intent: isMediumStrength ? 'info' : undefined,
          helpText: (
            <Typography.Text intent="subdued">
              Complete a few more steps to get your profile filled with
              information employers care about.
            </Typography.Text>
          ),
        },
        {
          id: 'high_strength',
          text: (
            <TimelineTitleTag
              tagIntent="success"
              icon="arrow-up"
              text="HIGH"
              variant={isHighStrength ? 'outlined' : 'basic'}
            />
          ),
          intent: isHighStrength ? 'info' : undefined,
          helpText: (
            <Typography.Text intent="subdued">
              Complete your full profile to be shown to all relevant employers
              and matched with more roles.
            </Typography.Text>
          ),
        },
      ]}
    />
  );
};

export default ProfileHealthTimeline;
