import { GroupedJobPreferencesData } from 'src/modules/User/types';

import {
  TCertificationHighlightQuestionV2,
  THighlightQuestion,
} from '../../SetupJobPreferences/types';

export const parseSalaryInfo = (
  salaryInfo: GroupedJobPreferencesData['SalaryInfo']
) => {
  const { currency, frequency, maximum, minimum } = JSON.parse(
    salaryInfo?.[0]?.value || '{}'
  );

  const currencyFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    notation: 'compact',
  });

  const salaryFrequencyMapping: Record<string, string> = {
    annum: 'year',
    month: 'month',
    hour: 'hour',
  };

  const minSalary = minimum || '';
  const maxSalary = maximum || '';
  const salaryFrequency = frequency ? salaryFrequencyMapping[frequency] : '';

  if (minSalary && maxSalary) {
    return `${currencyFormatter.format(minSalary)} - ${currencyFormatter.format(
      maxSalary
    )} ${currency} per ${salaryFrequency}`;
  }

  if (minSalary || maxSalary) {
    const salary = minSalary || maxSalary;

    return `${currencyFormatter.format(
      salary
    )} ${currency} per ${salaryFrequency}`;
  }

  return '';
};

export const parseNoticePeriod = (noticePeriod: string) => {
  if (noticePeriod === 'immediately') {
    return 'Available now';
  }

  if (noticePeriod === 'flexible') {
    return 'Flexible';
  }

  if (noticePeriod === '4+ weeks') {
    return '';
  }

  if (noticePeriod) {
    return `${noticePeriod} notice`;
  }

  return '';
};

export const parseEducationLevel = (
  educationLevels: Array<string | null | undefined>
) => {
  if (educationLevels.includes('doctorate_degree')) return 'Doctorate Degree';
  if (educationLevels.includes('master_degree')) return "Master's Degree";
  if (educationLevels.includes('bachelor_degree')) return "Bachelor's Degree";
  if (educationLevels.includes('certificate_or_diploma'))
    return 'Certificate or Diploma';
  if (educationLevels.includes('vocational_school')) return 'Vocational School';
  if (educationLevels.includes('high_school')) return 'High School';
  return null;
};

export const formatHighlightQuestions = (
  highlightQuestions: THighlightQuestion[]
) => {
  const certificationHighlightItems = highlightQuestions.filter(
    (item): item is TCertificationHighlightQuestionV2 =>
      item.question_type === 'certification_multiselect'
  );
  const certificationHighlightItemsV1 = certificationHighlightItems.map(
    item => ({
      id: item.id,
      name: item.question_metadata.standard_value,
      answer: item.associated_object ? 'yes' : 'no',
    })
  );

  return certificationHighlightItemsV1;
};
