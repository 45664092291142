import React from 'react';

import StatusComponent from '../StatusComponent';

type SmartMatchesProps = { smartMatchCount: number };

const SmartMatchesStatus = ({ smartMatchCount }: SmartMatchesProps) => (
  <StatusComponent
    imageSrc="/assets/images/offboard-employee.png"
    imageAltText="smart matches icon"
    titleText={
      smartMatchCount > 0
        ? `You have ${smartMatchCount} matches`
        : 'You have matches!'
    }
    subtitleText={`${smartMatchCount} Employers are interested in your profile. View your top matches and decide which to apply for with just a click.`}
    buttonProps={{
      variant: 'filled',
      text: 'View top matches',
      url: '/user/job_matches',
    }}
  />
);

export default SmartMatchesStatus;
