import React, { useMemo, useState } from 'react';

import { Box, Button, Collapse, Typography } from '@hero-design/react';

import { countTime, getPeriod } from '../../../utils/helpers';
import { TEmploymentHistory } from '../../../types';

import {
  DetailContainer,
  DetailDuration,
  DetailHeader,
} from './SharedComponents';

type EmploymentDetailProps = {
  employmentDetail: TEmploymentHistory;
};

type EmploymentHistoriesProps = {
  employmentHistories: TEmploymentHistory[];
};

const EmploymentDetail = ({ employmentDetail }: EmploymentDetailProps) => {
  const {
    job_title: jobTitle,
    company,
    summary,
    is_verified: isVerified,
  } = employmentDetail;

  return (
    <Box mb="medium">
      <Box>
        <Typography.Text fontWeight="semi-bold">{jobTitle}</Typography.Text>
        <Typography.Text intent="subdued">
          <DetailDuration
            period={{
              periodLength: getPeriod(employmentDetail),
              duration: countTime(employmentDetail),
            }}
            isVerified={isVerified}
          />
        </Typography.Text>
        <Typography.Text intent="subdued">{company}</Typography.Text>
      </Box>
      {summary && (
        <Typography.Text sx={{ pt: 'medium' }}>{summary}</Typography.Text>
      )}
    </Box>
  );
};

const EmploymentHistories = ({
  employmentHistories,
}: EmploymentHistoriesProps) => {
  const [showMore, setShowMore] = useState(false);
  const { displayedItems, moreItems } = useMemo(
    () => ({
      displayedItems: employmentHistories.slice(0, 2),
      moreItems: employmentHistories.slice(2),
    }),
    [employmentHistories]
  );

  return (
    <DetailContainer>
      <DetailHeader text="Recent experience" />

      {displayedItems.map(employment => (
        <EmploymentDetail key={employment.id} employmentDetail={employment} />
      ))}
      {moreItems.length > 0 && (
        <>
          <Collapse open={showMore}>
            {moreItems.map(employment => (
              <EmploymentDetail
                key={employment.id}
                employmentDetail={employment}
              />
            ))}
          </Collapse>
          <Button
            variant="text"
            intent="primary"
            sx={{ p: 0 }}
            data-test-id="employment-history-see-more-and-less-button"
            text={showMore ? 'See less' : 'See more'}
            onClick={() => setShowMore(!showMore)}
          />
        </>
      )}
    </DetailContainer>
  );
};

export default EmploymentHistories;
